<template>
  <b-row id="modal-snippets">
    <b-col md="6" v-if="origen=='callback'">            
      <b-form-group label="Callback">
        <v-select :options="arr.snippets.callback" v-model="snippets.callback" placeholder="Bloque de Código" :multiple="false" :select-on-tab="true" @input="selectCode('callback')"></v-select> 
      </b-form-group>
    </b-col>                  
    <b-col md="6" v-if="origen=='interface'">      
      <b-form-group label="Interfaces">
        <v-select :options="arr.snippets.interfaces" v-model="snippets.interfaces" placeholder="Bloque de Código" :multiple="false" :select-on-tab="true" @input="selectCode('interfaces')"></v-select> 
      </b-form-group>
    </b-col>
    <b-col md="6" v-if="origen=='cron'">      
      <b-form-group label="Cron">
        <v-select :options="arr.snippets.cron" v-model="snippets.cron" placeholder="Bloque de Código" :multiple="false" :select-on-tab="true" @input="selectCode('cron')"></v-select> 
      </b-form-group>
    </b-col>    
    <b-col md="6">      
      <b-form-group label="Funciones">      
        <v-select :options="arr.snippets.functions" v-model="snippets.functions" placeholder="Bloque de Código" :multiple="false" :select-on-tab="true" @input="selectCode('functions')"></v-select> 
      </b-form-group>
    </b-col>                          
    <b-col md="12">
      <b-button variant="success" class="mr-1 w-100" size="sm" @click="copyToClipboard()" v-if="code">
        <i class="fa fa-copy"></i>
        Copiar Código
      </b-button>                     

      <prism-editor v-model="code" language="php" class="php-snippets-code" :readonly="true"></prism-editor>                                    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'    

  import PrismEditor from 'vue-prism-editor'
  import Prism from "prismjs";  
  import "prismjs/themes/prism.css";  
  import "prismjs/components/prism-markup-templating";  
  import 'prismjs/components/prism-php';


  export default {
    components: {      
      PrismEditor,
    },              
    props: [
      'origen',
    ],
    data() {
      return {        
        snippets: {
          callback: null,
          interfaces: null,
          cron: null,
          functions: null,          
        },
        code: '',
        arr: {
          snippets: {
            callback: [],
            interfaces: [],
            cron: [],
            functions: [],
          },          
        },        
      }
    },
    watch: {        
      origen: function (newQuestion, oldQuestion) {
        this.load()
      }
    },            
    mounted(){           
      this.load()                  
    },    
    methods: {
      load() {      
        var result = serviceAPI.obtenerPHPSnippets(this.origen)

        result.then((response) => {
          var data = response.data

          data.forEach(element => {      
            if(element.type == 'callback')
              this.arr.snippets.callback.push({code: element.id, label: element.name, contents: element.contents})

            if(element.type == 'interfaces')
              this.arr.snippets.interfaces.push({code: element.id, label: element.name, contents: element.contents})            

            if(element.type == 'cron')
              this.arr.snippets.cron.push({code: element.id, label: element.name, contents: element.contents})                        

            if(element.type == 'functions')
              this.arr.snippets.functions.push({code: element.id, label: element.name, contents: element.contents})                        
          });
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });                
      },
      selectCode(type) {
        this.code = ''

        if(type=='callback') {
          this.code = this.snippets.callback.contents
          this.snippets.interfaces = null
          this.snippets.cron = null
          this.snippets.functions = null
        }
          
        if(type=='interfaces') {
          this.code = this.snippets.interfaces.contents
          this.snippets.callback = null
          this.snippets.cron = null
          this.snippets.functions = null
        }
         
        if(type=='cron') {
          this.code = this.snippets.cron.contents
          this.snippets.callback = null
          this.snippets.interfaces = null
          this.snippets.functions = null
        }

        if(type=='functions') {
          this.code = this.snippets.functions.contents 
          this.snippets.callback = null
          this.snippets.interfaces = null
          this.snippets.cron = null
        }          
      },
      copyToClipboard() {                        
        var modal = document.getElementById('modal-snippets');
        var textarea = document.createElement('textarea');          
        textarea.value = this.code 
        modal.appendChild(textarea);          
        textarea.select();
        document.execCommand('copy');
        modal.removeChild(textarea);
                    
        this.$awn.success("Código copiado al portapapeles");        
      }                   
    }
  }
</script>

<style>
  .php-snippets-code {
    height: 400px;
  }
  .php-snippets-code pre {
    height: 400px;
    margin-top: 0px;
  }
</style>