<template>
  <b-row>
    <b-col sm="12">

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col v-if="php">
            <h4 id="traffic" class="card-title mb-0">{{php.name}}</h4>            
            <div class="small text-muted">{{php.observations}}</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col md="12">
            <b-button type="button" variant="warning w-100" size="sm" @click="openSnippets()">
              <i class="fa fa-code"></i>
              Bloques de Código
            </b-button>  
          </b-col>
          <b-col  md="12">       
            <prism-editor v-model="crud.form.code" language="php" class="php-editor-code" :readonly="php && php.blocked"></prism-editor>                        
          </b-col>
        </b-row>

        <template v-slot:footer>
          <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)" class="pull-left">
            <i class="fa fa-angle-double-left"></i>
            Volver
          </b-button>

          <b-button variant="dark" @click="save()" size="sm" class="pull-right" v-if="php && !php.blocked">Guardar</b-button>          
        </template>         
      </b-card>      

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.snippets.active"
              size="xl"
              hide-footer
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.snippets.title}}
        </div>
        
        <Snippets v-if="this.php" :origen="php.type"></Snippets>          
                
        <b-button variant="secondary" class="mr-1 w-100" size="sm" @click="modal.snippets.active=false">
          Cerrar
        </b-button>                     
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import moment from 'moment'
  
  import Snippets from './snippets/snippets'

  import PrismEditor from 'vue-prism-editor'
  import Prism from "prismjs";  
  import "prismjs/themes/prism.css";  
  import "prismjs/components/prism-markup-templating";  
  import 'prismjs/components/prism-php';

  export default {  
    components: {      
      PrismEditor,
      Snippets,
    },          
    data: () => {
      return {      
        access: {
          module_id: Modules.PHP,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPHPCode',
          elements: {}
        },
        crud: {
          form: {
            id: '',
            code: '',
            blocked: false,            
          },          
        },         
        modal: {
          snippets: {
            active: false,
            title: ''
          },
        },
        php: null,                       
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {              
      this.load()
    },
    methods: {  
      load() {
        if(!this.$route.params.id) {
          this.$router.go(-1)
        }

        this.crud.form.id = this.$route.params.id  
        var result = serviceAPI.mostrarPHP(this.crud.form.id)

        result.then((response) => {
          var data = response.data
          this.crud.form.id = data.id
          this.crud.form.code = data.code

          this.php = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      }, 
      save() {
        let loader = this.$loading.show();
        
        var result = serviceAPI.guardarCodePHP(this.crud.form);
                
        result.then((response) => {                    
          loader.hide()
          this.load()
          this.$awn.success("Código guardado");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },   
      
      openSnippets() {
        this.modal.snippets.title = "Bloques de Código"
        this.modal.snippets.active = true
      }      
    }      
  }
</script>
<style>
  .php-editor-code {
    height: 500px;
  }
  .php-editor-code pre {
    height: 500px;
  }
</style>